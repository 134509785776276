<template>
  <div class="ui fluid container">
    <div class="ui menu">
      <menu-item
        :action="add"
        classes="primary basic"
        icon="plus"
        label="Add"
      ></menu-item>
    </div>
    <div class="ui raised segment">
      <table class="ui selectable striped table">
        <thead>
          <tr>
            <th>Name</th>
            <th>PMS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading.inks">
            <td colspan="3"><i class="loading spinner icon" /></td>
          </tr>
          <router-link
            custom
            v-slot="{ navigate }"
            v-else
            v-for="ink in inks"
            :key="ink.id"
            :to="{ name: 'Ink', params: { id: ink.id } }"
          >
            <tr @click="navigate" :class="{ 'warning': !ink.is_active }">
              <td>
                <span class="ui label" :style="`background-color: ${ink.RGB}; border-color: ${ink.RGB};`"></span>
                {{ ink.name }}
              </td>
              <td>{{ ink.PMS ? ink.PMS : '-' }}</td>
              <td><i class="right chevron icon" /></td>
            </tr>
          </router-link>
        </tbody>
      </table>

      <p v-if="paginator.count">
        Displaying results {{ (paginator.currentPage - 1) * 10 + 1 }}-{{
          (paginator.currentPage - 1) * 10 + 11 > paginator.count
            ? paginator.count
            : (paginator.currentPage - 1) * 10 + 11
        }}
        of {{ paginator.count }}.
      </p>
      <div v-if="paginator.count" class="ui pagination menu">
        <a
          v-for="page in paginator.pages"
          :key="page"
          :class="{ active: page === paginator.currentPage }"
          @click="page !== paginator.currentPage ? changePage(page) : () => {}"
          class="item"
        >
          {{ page }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    MenuItem
  },

  data() {
    return {
      paginator: {
        count: 0,
        currentPage: 1,
        pages: null
      },

      loading: {
        inks: true
      }
    };
  },

  computed: {
    isLoading() {
      return Object.keys(this.loading).reduce(
        (acc, v) => acc || this.loading[v],
        false
      );
    },

    ...mapState(["inks"])
  },

  methods: {
    add() {
      this.$router.push({ name: "Ink", params: { id: "new" } });
    },

    changePage(page) {
      this.paginator.currentPage = page;
      let query = `?page=${page}`;

      this.loading.inks = true;
      this.loadInks({ query }).then(data => {
        this.paginator.count = data.count;
        this.paginator.pages = data.pages;
        this.loading.inks = false;
      });
    },

    ...mapActions(["loadInks"])
  },

  async created() {
    const data = await this.loadInks();
    this.paginator.count = data.count;
    this.paginator.pages = data.pages;
    this.loading.inks = false;
  }
};
</script>

<style scoped>
.container {
  padding: 0 3em 0 3em;
}
</style>
